import Sale from './sale'

export default function SaleCpu(props) {
  return (
    <Sale {...props} >
      <div>GPUをお得に購入</div>
      <ul>
        <li><a href="https://amzn.to/3mtypZp" rel="nofollow noopener" target="_blank">Amazon：現金・銀行振込によるポイントチャージで最大2.5%割引</a>。※チャージしたポイントでもポイントが付くため実質二重取り可能</li>
        <li><a href="https://click.linksynergy.com/fs-bin/click?id=xsv0FDnJs1M&offerid=83593.10000209&type=3&subid=0" target="_blank" rel="nofollow noopener">パソコン工房</a><img width="1" alt="" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=83593.10000209&type=3&subid=0" />や<a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=886483488" target="_blank" rel="nofollow noopener">TSUKUMO</a><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=886483488" height="1" width="1" alt=""/>もGPUを買うなら要チェック
        </li>
      </ul>
      <div>GPUを買うならパソコンとセットがお得</div>
      <ul>
        <li><a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=886847771" rel="nofollow noopener" target="_blank">G-Tune</a><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=886847771" height="1" width="1" alt=""/>: コスパが良く、初めてのパソコン購入に最適な安心なマウスコンピュータのゲーミングブランド</li>
        <li><a href="https://click.linksynergy.com/fs-bin/click?id=xsv0FDnJs1M&offerid=83593.10000209&type=3&subid=0" target="_blank" rel="nofollow noopener">パソコン工房</a><img border="0" width="1" alt="" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=83593.10000209&type=3&subid=0"/>：パソコンショップの老舗、リーゾナブルな価格で抜群のスペック</li>
        <li><a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=886899662" target="_blank" rel="nofollow noopener"><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=886899662" height="1" width="1" border="0" alt=""/>SEVEN</a>:パーツからパソコンを探せるMade in Akibaショップ、高額な商品ほどお得度が増す</li>
      </ul>
    </Sale>
  );
}